import anagrafiche from './anagrafiche'
import exports from './exports'
import home from './home'
import mensilita from './mensilita'
import movimenti from './movimenti'
import risultati from './risultati'
import tabelle from './tabelle'
import uniemens from './uniemens'
import stampe from './stampe'
import utility from './utility'

export default {
    anagrafiche,
    exports,
    home,
    mensilita,
    movimenti,
    risultati,
    tabelle,
    stampe,
    uniemens,
    utility,
}
