import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VRegioneStatuto'

class API {
    @call({ url: `${BASE_URL}/GetVRegioneStatuto` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<VInailClasse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVRegioneStatuto` })
    static async save(
        payload: SaveVInailClasse,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveVInailClasse>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
