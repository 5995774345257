import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/ElabDipte'

class API {
    @call({ url: `${BASE_URL}/GetDipteElaborazione` })
    static async getElaborazione(
        payload: GetDipteElaborazione,
    ): Promise<AxiosResponse<BasicResponseData<ElabDipte>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetRisultatiElaborazioneDipte` })
    static async getRisultati(
        payload: GetRisultatiElaborazioneDipte,
    ): Promise<AxiosResponse<BasicResponsePayload<ElaborazioniResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveDipteElaborazione` })
    static async saveRisultati(
        payload: SaveDipteElaborazionePayload,
    ): Promise<AxiosResponse<BasicResponsePayload<ElaborazioniResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveInserisciRisultatiDipte` })
    static async saveInserisci(
        payload: SaveInserisciRisultatiDipte,
    ): Promise<AxiosResponse<BasicResponsePayload<ElaborazioniResponse>> | null> {
        return null
    }
}

export default {
    getElaborazione: API.getElaborazione,
    getRisultati: API.getRisultati,
    saveRisultati: API.saveRisultati,
    saveInserisci: API.saveInserisci,
}
