import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

export const BASE_URL_DIPTE = '/Dipte'
class API {
    @call({ url: `${BASE_URL_DIPTE}/GetDipendenti` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/GetDipendentiExport` })
    static async getDipendentiExport(
        payload: any,
    ): Promise<AxiosResponse<BasicResponseData<DipteResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/DeleteDipte`, method: 'delete' })
    static async delete(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteAreaProduttiva` })
    static async saveDipteAreaProduttiva(
        payload: SaveDipteAreaProduttivaPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteUnitaProduttiva>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDetrazioniECaricoFamiglia` })
    static async saveDetrazioniECaricoFamiglia(
        payload: SaveDetrazioniECaricoFamigliaPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteCaricoFamiglia>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteSocio` })
    static async saveDipteSocio(
        payload: SaveDipteSocioPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteSocio>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteErede` })
    static async saveDipteErede(
        payload: SaveDipteSocioPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteErede>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteSommDistaccato` })
    static async saveDipteSommDistaccato(
        payload: SaveDipteSommDistaccatoPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteSommDistaccato>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteCollaboratore` })
    static async saveDipteCollaboratore(
        payload: SaveDipteCollaboratorePayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteCollaboratore>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDiptePrecRapporto` })
    static async saveDiptePrecRapporto(
        payload: SaveDiptePrecRapportoPayload,
    ): Promise<AxiosResponse<BasicResponseData<DiptePrecRapporto>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/DipteSocioCalcolaRetribuzioneAnnua` })
    static async dipteSocioCalcolaRetribuzioneAnnua(
        payload: DipteSocioCalcolaRetribuzioneAnnuaPayload,
    ): Promise<AxiosResponse<
        BasicResponseData<DipteSocioCalcolaRetribuzioneAnnuaResponse>
    > | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/CalcolaNaspi` })
    static async calcolaNaspi(
        payload: CalcolaNaspiPayload,
    ): Promise<AxiosResponse<BasicResponseData<CalcolaNaspiResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteSindENettoC` })
    static async saveDipteSindENettoC(
        payload: SaveDipteSindENettoCPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteSindENettoC>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/DuplicaDipendentiJob` })
    static async DuplicaDipendentiJob(
        payload: DuplicaDipendentiJobPayload,
    ): Promise<AxiosResponse<BasicResponseData<DuplicaDipendentiJob>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/AdvancedDeleteDipendentiJob` })
    static async saveAdvancedDeleteDipendentiJob(
        payload: AdvancedDeleteDipendentiJobPayload,
    ): Promise<AxiosResponse<BasicResponseData<AdvancedDeleteDipendentiJob>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/CollegamentoDipendenteCollaboratore` })
    static async collDipteColl(
        payload: CollegamentoDipendenteCollaboratorePayload,
    ): Promise<AxiosResponse<BasicResponsePayload<CollegamentoDipendenteCollaboratore>> | null> {
        return null
    }
}

export default {
    get: API.get,
    getDipendentiExport: API.getDipendentiExport,
    delete: API.delete,
    //
    saveDipteAreaProduttiva: API.saveDipteAreaProduttiva,
    saveDetrazioniECaricoFamiglia: API.saveDetrazioniECaricoFamiglia,
    saveDipteSocio: API.saveDipteSocio,
    saveDipteErede: API.saveDipteErede,
    saveDipteCollaboratore: API.saveDipteCollaboratore,
    saveDiptePrecRapporto: API.saveDiptePrecRapporto,
    saveDipteSommDistaccato: API.saveDipteSommDistaccato,
    //
    dipteSocioCalcolaRetribuzioneAnnua: API.dipteSocioCalcolaRetribuzioneAnnua,
    calcolaNaspi: API.calcolaNaspi,
    //
    saveDipteSindENettoC: API.saveDipteSindENettoC,
    DuplicaDipendentiJob: API.DuplicaDipendentiJob,
    saveAdvancedDeleteDipendentiJob: API.saveAdvancedDeleteDipendentiJob,
    collDipteColl: API.collDipteColl,
}
