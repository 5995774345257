const banche = () => import('./Banche.vue')
const Caaf = () => import('./Caaf.vue')
const classiDiRischioArtigiani = () => import('./ClassiDiRischioArtigiani.vue')
const codiceDescrizione = () => import('./CodiceDescrizione.vue')
const CodiciContrattoCNEL = () => import('./CodiciContrattoCNEL.vue')
const CodiciContrattoCO = () => import('./CodiciContrattoCO.vue')
const codiciEsazione = () => import('./CodiciEsazione.vue')
const codiciOutput = () => import('./CodiciOutput.vue')
const codiciTributo = () => import('./CodiciTributo.vue')
const comuni = () => import('./Comuni.vue')
const constanti = () => import('./Costanti.vue')
const contrattoInps = () => import('./ContrattoInps.vue')
const creditoImposta = () => import('./CreditoImposta.vue')
const dataVersamentoF24 = () => import('./DataVersamentoF24.vue')
const datoFisso = () => import('./DatoFisso.vue')
const datoMese = () => import('./DatoMese.vue')
const FormatoAliquota = () => import('./FormatoAliquota.vue')
const InailInteressi = () => import('./InailInteressi.vue')
const InailSedi = () => import('./InailSedi.vue')
const IrpefDetrazione = () => import('./IrpefDetrazione.vue')
const IrpefDetrazioneAltriCarichi = () => import('./IrpefDetrazioneAltriCarichi.vue')
const IrpefDetrazioneArt13C5 = () => import('./IrpefDetrazioneArt13C5.vue')
const IrpefDetrazioneArt13Co5 = () => import('./IrpefDetrazioniArt13Co5.vue')
const IrpefDetrazioneConiuge = () => import('./IrpefDetrazioneConiuge.vue')
const IrpefDetrazioniFigli = () => import('./IrpefDetrazioneFigli.vue')
const IrpefScaglioniImposta = () => import('./IrpefScaglioniImposta.vue')
const IrpefTrattIntegr = () => import('./IrpefTrattIntegr.vue')
const maggiorazioniSpettanti = () => import('./MaggiorazioniSpettanti.vue')
const messaggi = () => import('./Messaggi.vue')
const proprietaAggiuntiva = () => import('./ProprietaAggiuntiva.vue')
const province = () => import('./Province.vue')
const qualificheDipte = () => import('./QualificheDipte.vue')
const qualificheInps = () => import('./QualificheInps.vue')
const situazione = () => import('./SituazioneRagioneStatutoSpeciale.vue')
const tipoRapporto = () => import('./TipoRapporto.vue')
const tipoRetribuzione = () => import('./TipoRetribuzione.vue')
const TipoSconto = () => import('./TipoSconto.vue')
const uniemens = () => import('./Uniemens.vue')
const unilav = () => import('./Unilav.vue')
const unimare = () => import('./Unimare.vue')
const vociDiTariffa = () => import('./VociDiTariffa.vue')

export {
    banche,
    Caaf,
    classiDiRischioArtigiani,
    codiceDescrizione,
    CodiciContrattoCNEL,
    CodiciContrattoCO,
    codiciEsazione,
    codiciOutput,
    codiciTributo,
    comuni,
    constanti,
    contrattoInps,
    creditoImposta,
    dataVersamentoF24,
    datoFisso,
    datoMese,
    FormatoAliquota,
    InailInteressi,
    InailSedi,
    IrpefDetrazione,
    IrpefDetrazioneAltriCarichi,
    IrpefDetrazioneArt13C5,
    IrpefDetrazioneArt13Co5,
    IrpefDetrazioneConiuge,
    IrpefDetrazioniFigli,
    IrpefScaglioniImposta,
    IrpefTrattIntegr,
    maggiorazioniSpettanti,
    messaggi,
    proprietaAggiuntiva,
    province,
    qualificheDipte,
    qualificheInps,
    situazione,
    tipoRapporto,
    tipoRetribuzione,
    TipoSconto,
    uniemens,
    unilav,
    unimare,
    vociDiTariffa,
}
