import utilityViews from '@paghe/views/utility'

const tabPropsDefault: TabProps = {
    GROUP: 'utility',
    ICON: 'fa-regular fa-wrench',
    TITLE: 'Utility',
    CODVOCEMENU: '000201', //FALSO, CORRISPONDE A COLLEGAMENTO DIPTE - COLLABORATORE , da sostituire con quello corretto
    CODPERMESSO: ['ANAG_DITTA', 'ANAG_DIPTE', 'ANAG_COLL'], //DA CAMBIARE CON QUELL OREWWLATIVO ALLE SINGOLE PAGINE - assegnare alal singola pagina e mettere ALL qui
}

//https://router.vuejs.org/guide/essentials/passing-props
// function propsFn(route) {
//     return {
//         year: Number(route.params.year),
//         month: Number(route.params.month),
//         day: Number(route.params.day),
//         id: Number(route.params.id),
//         idPadre: Number(route.params.idPadre),
//         isAllDitta: String(route.params.isAllDitta) === '1',
//         isCo: String(route.params.isCo) === '1',
//     }
// }

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: 'Archivi anagrafici - Utility - Paghe',
        LABEL: 'Dettaglio utility anagrafici',
        PATH: 'utility/anagrafici',
        NAME: 'UtilityAnagrafici',
        TAB: { ...tabPropsDefault, TITLE: 'Utilità archivi anagrafici' },
    },
    DIPTE_COLL_LINK: {
        TITLE: 'Collegamento dipendente <-> collaboratore - Archivi anagrafici - Utility - Paghe',
        LABEL: 'Collegamento dipendente <-> collaboratore',
        PATH: 'dipteColl',
        NAME: 'UtilityAnagraficiDipteCollLink',
        TAB: { ...tabPropsDefault, TITLE: 'Utilità archivi anagrafici' },
    },
})

const routes: Route[] = [
    {
        path: SETTINGS.DETTAGLIO.PATH,
        name: SETTINGS.DETTAGLIO.NAME,
        props: true, //TODO: SE SERVE USARE IMPLEMENTARE PROPSFN
        component: utilityViews.anagrafici.Dettaglio,
        meta: {
            title: SETTINGS.DETTAGLIO.TITLE,
            permissions: {
                codPermesso: tabPropsDefault.CODPERMESSO,
                codVoceMenu: null, //tabPropsDefault.CODVOCEMENU, //TODo: mettere la voce futura
            },
            idPaginaMsg: 'VALID',
            tabGroup: tabPropsDefault.GROUP,
        },
        children: [
            {
                label: SETTINGS.DIPTE_COLL_LINK.LABEL,
                path: SETTINGS.DIPTE_COLL_LINK.PATH,
                name: SETTINGS.DIPTE_COLL_LINK.NAME,
                tab: SETTINGS.DIPTE_COLL_LINK.TAB,
                component: utilityViews.anagrafici.childs.DipteCollLink,
                meta: {
                    title: SETTINGS.DIPTE_COLL_LINK.TITLE,
                    group: SETTINGS.DETTAGLIO.NAME, //GRUPPO DEL DETTAGLIO
                    permissions: {
                        codPermesso: tabPropsDefault.CODPERMESSO,
                        codVoceMenu: tabPropsDefault.CODVOCEMENU,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                    idPaginaMsg: 'UTYDIPCOL',
                },
                orderWizard: 0,
                props: true,
            },
        ],
    },
]

export default {
    SETTINGS,
    routes,
}
