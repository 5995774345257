import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000016',
    GROUP: 'compCollaboratori',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'COMPENSI',
    CODPERMESSO: ['COMPENSO_COLL'],
}

function propsFn(route) {
    return {
        year: Number(route.params.year),
        month: Number(route.params.month),
        id: Number(route.params.id),
        idPadre: Number(route.params.idPadre),
        is0112: String(route.params.is0112) === '0',
        tipoCall: String(route.params.tipoCall),
    }
}

const SETTINGS = Object.freeze({
    DITTE_LISTA: {
        NAME: 'compCollaboratori',
        PATH: 'compensiCollaboratori',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Gestione - Corrente - Compensi Collaboratori: Elenco Ditte',
    },
    COLLABORATORI_LISTA: {
        NAME: 'compCollaboratoriELenco',
        PATH: 'compensi/collaboratori/elenco-collaboratori/:year/:month/:id/:idPadre/:is0112/:tipoCall?',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Gestione - Corrente - Compensi Collaboratori: Elenco Collaboratori',
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DITTE_LISTA.PATH,
            name: SETTINGS.DITTE_LISTA.NAME,
            component: pagheViews.movimenti.compensi.ElencoDitte,
            meta: {
                title: SETTINGS.DITTE_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'MOVCOLL',
            },
        },
        {
            path: SETTINGS.COLLABORATORI_LISTA.PATH,
            name: SETTINGS.COLLABORATORI_LISTA.NAME,
            component: pagheViews.movimenti.compensi.ElencoDipendenti,
            meta: {
                title: SETTINGS.COLLABORATORI_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'MOVCOLL',
            },
            props: propsFn,
        },
    ],
}
