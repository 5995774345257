import { castArray, cloneDeep, get, has, isArray, isObject } from 'lodash'

import { INDEXED_DB } from '@/constants'
import { isBetween, isAfter, isBefore, DateRec } from '@/scripts/services/date'
import { useGlobalStore } from '@/stores'

export async function setDataDb(tableList: string = [], isInclude: boolean = true) {
    const globalStore = useGlobalStore()

    window.indexedDB.deleteDatabase(INDEXED_DB.DB_NAME) //Eliminazione del database

    globalStore.loadingSet('Inizializzazione in corso ...')
    const promises: any[] = []
    Object.values(INDEXED_DB.STORES).forEach(async store => {
        if (tableList.length && isInclude !== tableList.includes(store.STORE_NAME)) return
        promises.push(
            new Promise(async resolve => {
                //Ciclo sull'iterazione di indexed-db
                const payload = {
                    ...store.PARAMS,
                    periodoDiRiferimento: {
                        year: 1900,
                        month: 1,
                        day: 1,
                        yearTo: 9999,
                        monthTo: 12,
                        dayTo: 31,
                    },
                }
                const result = await store.API().get(payload, !store.__MODULES) //chiamta APi
                if (result?.data?.responseStatus?.isSuccessful) {
                    const data = get(result, `data.${store.DATA_ROOT}`, []) //get di lodash per pulire i dati
                    // X BUGFIX COMUNI MULTI-VALIDITA'
                    // if (INDEXED_DB.CALLBACK) INDEXED_DB.CALLBACK(data) ===> raggruppare i comuneDato
                    // di ogni elemento comune con stesso codice sotto un unico elemento
                    //
                    if (store?.CALLBACK) store.CALLBACK(data)
                    await setDataDbItem(store, store?.CALLBACK ? store.CALLBACK(data) : data) //Funzione che salva i dati all'indexBD
                }
                resolve(true)
            }),
        )
    })
    await Promise.all(promises)
    globalStore.loadingUnset()

    /**
     *
     * @param store Passa all'interno dello store dove sono contenute le informazioni per ogni singola chiamata
     * @param data I dati che ritorna la chiamata API
     */

    async function setDataDbItem(store: object, data: Array<object>) {
        const request: IDBOpenDBRequest = window.indexedDB.open(INDEXED_DB.DB_NAME) // ottiene la request
        request.onupgradeneeded = event => {
            const db = event?.target?.result // ottiene il db dalla request
            Object.values(INDEXED_DB.STORES).forEach(_store => {
                db.createObjectStore(_store.STORE_NAME, { keyPath: _store.KEY }) // crea  lo store
            })
        }

        request.onsuccess = event => {
            const db = event.target.result
            const transaction = db.transaction(store.STORE_NAME, 'readwrite') // apre una transazione
            const storeDB = transaction.objectStore(store.STORE_NAME) // ottine lon store dalla transazione
            data.forEach((item: Object) => storeDB.add(item)) // popola lo store
            db.close()
        }
    }
}

//Funzione per il recupero dei dati
export function getDataDb(store: any, payload: any, pks: number[] | string[] | null): Promise<any> {
    const globalStore = useGlobalStore()
    const periodo =
        payload?.periodoDiRiferimento !== undefined
            ? payload?.periodoDiRiferimento
            : globalStore.state.periodoElab //puo essere null
    return new Promise(resolve => {
        const request: any = window.indexedDB.open(INDEXED_DB.DB_NAME) // apertura IndexDB
        const data = { responseStatus: { isSuccessful: true }, [store.DATA_ROOT]: null } // Ogetto costriuto simile al ritorno dell'API

        request.onsuccess = async (event: ProgressEvent) => {
            const db = event.target.result
            const storeNames = Array.from(db.objectStoreNames) //Presi tutti gli store esistenti

            const exists = storeNames.includes(store.STORE_NAME) // controllo se uno store esiste
            if (!exists) {
                resolve(data)
                return
            }
            const transaction = db.transaction(store.STORE_NAME, 'readonly') //Specifica gli archivi su cui deve entrare
            const storeDB = transaction.objectStore(store.STORE_NAME) // Entra nel singolo archivio di dati
            let _data: any[] = []
            if (pks) {
                const promises: any[] = []
                pks.forEach(funz => {
                    promises.push(
                        new Promise(async resolve1 => {
                            storeDB.get(funz).onsuccess = (event: any) => {
                                _data.push(event.target.result || [])
                                resolve1(true)
                            }
                        }),
                    )
                })
                await Promise.all(promises)
                data[store.DATA_ROOT] = periodo
                    ? filterPeriodo(store, periodo, cloneDeep(_data))
                    : cloneDeep(_data)
                db.close() // chiudo il db
                resolve(data)
            } else {
                // recupera tutti i dati all'interno
                storeDB.getAll().onsuccess = (event: any) => {
                    data[store.DATA_ROOT] = periodo
                        ? filterPeriodo(store, periodo, event.target.result || [])
                        : cloneDeep(event.target.result)
                    db.close() // chiudo il db
                    resolve(data)
                }
            }
        }
    })
}

function filterPeriodo(store: any, periodo: any, data: any[]) {
    let _data = cloneDeep(data)
    if (has(store, 'PARAMS.include') && has(store, 'VALIDITY')) {
        // filtro validità su elementi figli (include):
        try {
            const includes = JSON.parse(store.PARAMS.include.replaceAll("'", '"'))
            let rootValidita: string[] = []
            switch (true) {
                // - per tutti gli include specificati in PARAMS
                case store.VALIDITY === true:
                    rootValidita = includes
                    break
                // - per tutti gli include specificati in store.VALIDITY
                case isArray(store.VALIDITY):
                    rootValidita = store.VALIDITY.filter((include: string) =>
                        includes.includes(include),
                    )
                    break
                // - solo per l'include specificato in store.VALIDITY
                case typeof store.VALIDITY === 'string' && includes.includes(store.VALIDITY):
                    rootValidita = castArray(store.VALIDITY)
                    break
            }
            if (rootValidita.length) {
                _data.forEach((itemParent: any) => {
                    rootValidita.forEach((include: string) => {
                        if (isArray(itemParent[include])) {
                            itemParent[include] = itemParent[include].filter((itemChild: any) =>
                                //isBetween(periodo, itemChild.dataInizio, itemChild.dataFine),
                                filterDate(periodo, itemChild),
                            )
                            return
                        }
                        if (isObject(itemParent[include])) {
                            if (
                                // !isBetween(
                                //     periodo,
                                //     itemParent[include]?.dataInizio,
                                //     itemParent[include]?.dataFine,
                                // )
                                !filterDate(periodo, {
                                    dataInizio: itemParent[include]?.dataInizio,
                                    dataFine: itemParent[include]?.dataFine,
                                })
                            ) {
                                itemParent[include] = null
                            }
                        }
                    })
                })
            }
        } catch (error) {}
    } else {
        // filtro validità a livello padre
        _data = _data.filter((item: any) => filterDate(periodo, item)) //isBetween(periodo, item.dataInizio, item.dataFine))
    }
    return _data
}

/**
 * Effettua il controllo sulla dataInizio e dataFine dell'item preso in considerazione rispetto al periodo considerato.
 * In caso di singolo periodo viene effettuato un controllo se tale periuodo e' compreso nel periodo dell'item; se range controlla l'item se compreso nel periodo considerato.
 * @param periodo periodo singolo (es: 02/2024) o range di validita (dataDal e dataAl).
 * @param item item contente dataInizio e dataFine
 */
export function filterDate(
    periodo: PeriodoDiRiferimento,
    item: { dataInizio: string; dataFine: string },
): boolean {
    if (!periodo || (item.dataFine && !item.dataInizio) || (!item.dataFine && item.dataInizio))
        return false
    if (!item.dataFine && !item.dataInizio) return true //se non esiste dataInizio e dataFine e' un oggetto senza periodo quindi sempre valido
    if (periodo.dayTo && periodo.monthTo && periodo.yearTo) {
        //item.dataInizio <= PF && item.dataFine >= PI
        return (
            isBefore(
                item.dataInizio,
                `${periodo.dayTo}/${periodo.monthTo}/${periodo.yearTo}`,
                true,
            ) && isAfter(item.dataFine, `${periodo.day}/${periodo.month}/${periodo.year}`, true)
        )
    }

    return isBetween(
        { day: periodo.day, month: periodo.month, year: periodo.year },
        item.dataInizio,
        item.dataFine,
    )
}

/**
 * Permette l'aggiornamento o l'inserimento di un singolo record all'interno di una determinata tabella all'inderno dell'INDEXEDDB
 * @param mode indica il tipo di operazione che puo' essere 'ins' o 'mod'
 * @param dbName nome della tabella su cui vogliamo apportare la modifica
 * @param data array di oggetti da salvare. Ogni oggetto in data deve avere al suo interno la chiave utilizzata dalla tabella
 * @param schemaName nome dello schema principale dove risiede la tabella da modificare
 * @returns
 */
export async function updateDataDb(
    mode: 'ins' | 'mod',
    dbName: string,
    data: Object[],
    schemaName: string = INDEXED_DB.DB_NAME,
) {
    if (!data) return
    const request: IDBOpenDBRequest = window.indexedDB.open(schemaName) // request per accedere al DB

    //In caso di success eseguo le mie operazioni
    request.onsuccess = event => {
        const db = event.target?.result
        const transaction = db.transaction(dbName, 'readwrite') // apre una transazione
        const objectStore = transaction.objectStore(dbName) //prende l'objectStore della transazione

        data.forEach((item: Object) => {
            switch (mode) {
                case 'ins':
                    objectStore.add(item) //aggiunge l'elemento
                    break
                case 'mod':
                    objectStore.put(item) //sovrascrive l'elemento
                    break
                default:
                    break
            }
        })
        db.close()
    }

    request.onerror = event => {
        console.log('Errore')
    }
}

/**
 * Permette l'eliminazione di un dato record all'interno di una tabella dell'INDEXED_DB
 * @param dbName nome della tabella su cui vogliamo apportare le modifiche
 * @param keys array di chiavi riferite ai record che bisogna eliminare
 * @param schemaName nome dello schema principale dove risiede la tabella da modificare
 */
export async function deleteDataDb(
    dbName: string,
    keys: string[],
    schemaName: string = INDEXED_DB.DB_NAME,
) {
    const request: IDBOpenDBRequest = window.indexedDB.open(schemaName) // request per accedere al DB

    //In caso di success eseguo le mie operazioni
    request.onsuccess = event => {
        const db = event.target?.result
        const transaction = db.transaction([dbName], 'readwrite') // apre una transazione
        const objectStore = transaction.objectStore(dbName) //prende l'objectStore della transazione

        keys.forEach((key: string) => objectStore.delete(key)) // elimina i record

        transaction.oncomplete = () => {
            db.close()
        }
    }

    request.onerror = event => {
        console.log('Errore')
    }
}
